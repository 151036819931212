export const ValidWorkStatus = consignment => {
  switch (consignment.typeId) {
    case 1:
      if (consignment.isConsignIn) {
        return [0, 1, 2, 3, 4, 7, 11, 13];
      } else {
        return [1];
      }
    case 2:
      if (consignment.isConsignIn) {
        return [0, 1, 2, 3, 4, 7, 9, 10, 11, 13, 14, 15];
      } else {
        return [0, 1, 2, 3, 4, 7, 10, 11, 13, 14, 15];
      }
    case 3:
      if (consignment.isConsignIn) {
        return [0, 1, 3, 7, 10];
      } else {
        return [];
      }
    case 4:
      if (consignment.isConsignIn) {
        return [0, 7];
      } else {
        return [];
      }
    default:
      return [];
  }
};
