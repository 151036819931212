import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import routes from "./routes";
import { Portal, getChangedFields } from "../../../../util";
import AuthRoute from "../../../../components/AuthRoute";
import { FETCH_CONSIGNMENT as fetchConsignment } from "../../../../graphql/query/Consignment";
import { UPDATE_CONSIGNMENT as updateConsignmentMutation } from "../../../../graphql/mutation/Consignment";
import { NavBar, Spinner } from "../../../../components/ui";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id: consignmentId } = useParams();
  const abortController = useRef();
  let { data: { consignment } = {}, loading } = useQuery(
    fetchConsignment({
      exhibition: {},
      consigner: {
        contactCompany: {
          images: {},
        },
      },
      contact: {},
    }),
    { skip: !consignmentId, variables: { id: +consignmentId } },
  );
  const [updateConsignment] = useMutation(
    updateConsignmentMutation({
      exhibition: {},
      consigner: {
        contactCompany: {
          images: {},
        },
      },
      contact: {},
    }),
    { refetchQueries: ["FetchConsignment"] },
  );

  const formik = useFormik({
    initialValues: consignment,
    validationSchema: Yup.object({
      typeId: Yup.string().required("Please select a type"),
      date: Yup.date().required("Please enter a date"),
      due: Yup.date()
        .nullable(true)
        .min(Yup.ref("date"), "Due date can't be before date")
        .when("typeId", {
          is: val => {
            val = parseInt(val);
            return val === 2;
          },
          then: schema => schema.required("Please enter a due date"),
        }),
      currencyId: Yup.number().required("Please select a currency"),
      notes: Yup.string().nullable(true),
    }),
    enableReinitialize: true,

    onSubmit: values => {
      const changedFields = getChangedFields(values, consignment);

      if (
        "contactId" in changedFields &&
        typeof changedFields?.contactId !== "number"
      ) {
        changedFields.consigneeCompany = null;
      }

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;

        updateConsignment({
          variables: {
            input: {
              id: +consignmentId,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        })
          .then(res => {
            consignment = res?.data?.updateConsignment?.consignment;
            formik.initialValues = consignment;
          })
          .catch(e => {
            console.log("E====>>", e?.message);
          });
      }
    },
  });

  useEffect(() => {
    if (formik?.values && abortController.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    consignmentId: +consignmentId,
    consignment,
    formik,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <Body {...bodyProps} loading={loading} />
    </div>
  );
};

export default Wizard;
