import contactFrag from "./Contact";
import currencyFrag from "./Currency";

const Company = ({ images = false, contact = false } = {}) => `
  id
  active
  bondMinValue
  code
  cds
  consignmentCredit
  consignmentTerms
  consignmentSignOff
  contactId
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  createdAt
  creditCode
  creditRef
  crossInvoiceId
  currencyId
  currency {
    ${currencyFrag}
  }
  hmrcAddress
  invoiceCode
  invoiceDue
  lobMarket
  loanTerms
  loanSignOff
  photoCredit
  name
  notes
  shippingEmail
  vatno
  ${images ? "images" : ""}
`;

export default Company;
