import { GridInputs } from "../../../../../../../components/Functional";
import useUser from "../../../../../../../hooks/useUser";

const Header = ({ formik }) => {
  const { hasPermission } = useUser();
  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Location History",
            name: "locationHistory",
            type: "textarea",
            submitOnBlur: true,
            disabled: true,
          },
          // {
          //   label: "Class",
          //   name: "class",
          //   type: "text",
          //   submitOnBlur: true
          // },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            label: "Status History",
            name: "statusHistory",
            type: "textarea",
            disabled: true,
            submitOnBlur: true,
          },
          // {
          //   label: "Activity",
          //   name: "activity",
          //   type: "text",
          //   submitOnBlur: true
          // },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_INSTANCE_SALES_STRATEGY"),
            label: "Sales Strategy",
            name: "salesStrategy",
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "flex flex-col gap-4",
        inputs: [
          {
            singleCheckbox: true,
            name: "returns",
            type: "checkbox",
            options: [{ label: "Returns", value: -1 }],
            submitOnBlur: true,
            onChange: e => {
              formik.setFieldValue("forSale", e.target.checked ? -1 : 0);
            },
          },
          {
            singleCheckbox: true,
            name: "returnsDirector",
            type: "checkbox",
            options: [{ label: "Director Confirmed", value: -1 }],
            submitOnBlur: true,
            onChange: e => {
              formik.setFieldValue("forSale", e.target.checked ? -1 : 0);
            },
          },
          {
            singleCheckbox: true,
            name: "parentOnline",
            type: "checkbox",
            options: [{ label: "Online to Parent Company", value: -1 }],
            submitOnBlur: true,
            onChange: e => {
              formik.setFieldValue("forSale", e.target.checked ? -1 : 0);
            },
          },
          {
            singleCheckbox: true,
            name: "forSale",
            type: "checkbox",
            options: [{ label: "For Sale", value: -1 }],
            submitOnBlur: true,
            onChange: e => {
              formik.setFieldValue("forSale", e.target.checked ? -1 : 0);
            },
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full border-b px-16 py-8">
      <GridInputs {...detailInputProps} />
    </div>
  );
};

export default Header;
