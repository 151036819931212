import instanceFrag from "./Instance";
import jobFrag from "./Job";

const JobItem = ({ job = false, instance = false } = {}) => `
  id
  ${
    job
      ? `job {
      ${jobFrag(job)}
    }`
      : ""
  }
  jobId
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  instanceId
  artistNames
`;

export default JobItem;
