import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";

import routes from "./routes";
import AuthRoute from "../../../../components/AuthRoute";
import { NavBar, Spinner } from "../../../../components/ui";
import { getChangedFields, Portal } from "../../../../util";
import { FETCH_JOB_REQUEST as fetchJob } from "../../../../graphql/query/Job";
import { UPDATE_JOB_REQUEST as updateJobMutation } from "../../../../graphql/mutation/Job";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const abortController = useRef();
  const { data: { job } = {}, loading } = useQuery(
    fetchJob({
      jobStatus: true,
      jobType: true,
      requestUser: true,
      user: true,
      artworkDescriptions: true,
      pickupGroup: true,
      jobItems: {
        instance: {
          status: {},
          condition: {},
        },
      },
    }),
    {
      variables: { id: +id },
    },
  );

  const [updateJob] = useMutation(updateJobMutation());

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const formik = useFormik({
    initialValues: job,
    enableReinitialize: true,
    validationSchema: Yup.object({
      expenses: Yup.number().positive().nullable(),
      hours: Yup.number().positive().nullable(),
      hoursvan: Yup.number().positive().nullable(),
      overtimehours: Yup.number().positive().nullable(),
      permanentHours: Yup.number().positive().nullable(),
      rate: Yup.number().positive().nullable(),
      rateOvertime: Yup.number().positive().nullable(),
      rateStaff: Yup.number().positive().nullable(),
      rateVan: Yup.number().positive().nullable(),
      technicians: Yup.number().positive().integer().nullable(),
      duetime: Yup.string()
        .required("Due-Time must be greater than or equal to 10 AM GMT")
        .test(
          "validation",
          `Due-Time must be greater than or equal to 10 AM GMT`,
          function (value) {
            const newDueTime = new Date(value);
            if (isValidDate(newDueTime)) {
              return newDueTime.getHours() >= 10;
            } else if (typeof value === "string") {
              return value.split(":")[0] >= 10;
            }
            return false;
          },
        ),
    }),
    onSubmit: values => {
      const changedFields = getChangedFields(values, job);

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;

        if (changedFields?.dueDate) {
          const dueDate = new Date(`${formik?.values?.dueDate}`);
          changedFields.dueDate = dueDate;
        }

        if (changedFields?.duetime) {
          const dueTime = new Date(
            `${formik?.values?.dueDate} ${formik?.values?.duetime}`,
          );
          changedFields.duetime = dueTime;
        }

        updateJob({
          variables: {
            input: {
              id: job?.id,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values && abortController.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    formik,
    job,
    jobId: +id,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex min-h-full flex-1 flex-col overflow-x-auto p-8">
        <Body loading={loading} {...bodyProps} />
      </div>
    </div>
  );
};

export default Wizard;
