import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { CLONE_JOB_REQUEST as cloneJobMutation } from "../../../graphql/mutation/Job";
import { FETCH_JOB_REQUEST as fetchJob } from "../../../graphql/query/Job";
import useUser from "../../../hooks/useUser";

import routes from "../pages/Wizard/routes";

import NewJob from "./NewJob";
import JobSearch from "./JobSearch";
import { jobFilterStore } from "./useJobsFilters";
import AdhocReportBody from "./Actions/AdhocReportBody";
import CreateShipment from "./Actions/CreateShipment";
import exportModule from "./Actions/ExportModule";
import { ExportTimeSheetBody } from "./Actions/ExportTimeSheet";
import { useState } from "react";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const search = jobFilterStore.get();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const pathnames = location?.pathname.split("/");
  const jobId = pathnames?.length > 2 ? pathnames[2] : null;

  const jobObj = {
    jobStatus: true,
    jobType: true,
    requestUser: true,
    user: true,
    artworkDescriptions: true,
    pickupGroup: true,
    jobItems: {
      instance: {
        status: {},
        condition: {},
      },
    },
  };

  const { data: { job } = {} } = useQuery(fetchJob(jobObj), {
    skip: !jobId,
    variables: { id: +jobId },
  });

  const [
    cloneJob,
    {
      data: { cloneJob: { error: cloneJobError } = {} } = {},
      loading: cloneJobLoading,
      reset: cloneJobReset,
    },
  ] = useMutation(cloneJobMutation());

  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(queryParams.get("listId") || status === "selectListWorking"
    ? queryParams.get("workingListId")
    : null);
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const statusMap = {
    all: "All Jobs",
    searched: "Searched Jobs Item",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Jobs";

  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/jobs?status=${status}`) },
    ...(job
      ? [
          {
            label: job?.id,
            onClick: () => navigate(`${job.id}/details?status=${status}`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${job.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const cloneJobProps = {
    title: cloneJobError ? "Error" : "Confirm Create Job",
    scale: "sm",
    description: cloneJobError
      ? cloneJobError
      : "Are you sure you wish to create new job?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleConfirm = () => {
        cloneJob({
          variables: {
            id: job?.id,
          },
          update: (cache, { data }) => {
            const { cloneJob: { job, success } = {} } = data || {};
            if (success) {
              closeModal();
              navigate(`${job.id}/details`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={cloneJobError ? "Close" : "Cancel"}
            action={cloneJobError ? "primary" : "default"}
            onClick={() => {
              cloneJobReset();
              closeModal?.();
            }}
          />
          {!cloneJobError && (
            <Button
              label={cloneJobLoading ? "Cloning" : "Confirm"}
              disabled={cloneJobLoading}
              onClick={handleConfirm}
            />
          )}
        </div>
      );
    },
  };

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };
  const [isExportReportOpen, setIsExportReportOpen] = useState(false);
  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        disabled: !hasPermission("CREATE_JOB"),
        label: "New Job",
        modalProps: {
          body: NewJob,
          closeOnBackdrop: false,
          contact: {},
          hideCloseButton: true,
          scale: "md",
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !job?.id && disabled,
        label: "Export Job Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: jobObj,
          type: "Job",
          ids: job?.id ? [job?.id] : ids,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
      {
        disabled,
        label: "Export Time Sheet",
        modalProps: {
          body: ExportTimeSheetBody,
          ...defaultActionProps,
        },
      },
      ...(job?.id
        ? [
            {
              label: "Create Job",
              modalProps: cloneJobProps,
            },
            {
              label: "Copy Archive Description",
              modalProps: {
                title: "Copy Description?",
                scale: "sm",
                description: "Do you wish to copy the archive description?",
                closeOnBackdrop: true,
                body: ({ closeModal }) => {
                  const handleCopyDescription = () => {
                    navigator.clipboard.writeText(job?.artworkDescriptions);
                    closeModal();
                  };

                  return (
                    <div className="flex flex-col">
                      <div className="py-2">
                        <p className="text-sm">
                          {!job?.artworkDescriptions
                            ? "No Description Available"
                            : ""}
                        </p>
                      </div>
                      <div className="mt-4 flex">
                        <Button
                          className="mr-3"
                          label={"Cancel"}
                          action={"default"}
                          onClick={() => {
                            closeModal?.();
                          }}
                        />
                        <Button
                          disabled={!job?.artworkDescriptions}
                          label={"Copy description"}
                          onClick={handleCopyDescription}
                        />
                      </div>
                    </div>
                  );
                },
              },
            },
            {
              label: "Create Shipment",
              modalProps: {
                body: CreateShipment,
                ...defaultActionProps,
                job,
              },
            },
          ]
        : []),
    ],
  };

  const searchModalProps = {
    body: JobSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
    isSearchOpen,
    onClose: () => setIsSearchOpen(false),
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button
            label="Jobs Search"
            action="black"
            onClick={() => setIsSearchOpen(true)}
          />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
