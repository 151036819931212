import { PencilIcon } from "@heroicons/react/24/outline";
import { IconButton } from "../../../../../../../components/ui/IconButton";
import { Modal } from "../../../../../../../components/ui/Modal";
import { AddOfferModal } from "./AddOffer";

const Actions = props => {
  const { id, values, artworkId } = props || {};

  const editModalProps = {
    body: AddOfferModal,
    hideCloseButton: true,
    scale: "md",
    values,
    artworkId: artworkId,
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid grid-cols-4 gap-4">
            <Modal {...editModalProps}>
              <IconButton variant="clean" title="Edit Offer">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
