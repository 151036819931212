import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import * as Yup from "yup";

import { FETCH_SHIPMENT as fetchShipment } from "../../../../graphql/query/Shipment";
import { FETCH_WAREHOUSE_MOVE_V2 as fetchWarehouseMoveV2 } from "../../../../graphql/query/WarehouseMove";
import AuthRoute from "../../../../components/AuthRoute";
import routes from "./routes";

import { NavBar, Spinner } from "../../../../components/ui";
import { Portal, getChangedFields } from "../../../../util";
import { useFormik } from "formik";
import { UPDATE_SHIPMENT as updateShipmentMutation } from "../../../../graphql/mutation/Shipment";
import { useEffect, useRef } from "react";

const Body = props => {
  const { loading, ...remainingProps } = props;

  return loading ? (
    <div className="h-100% flex w-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...remainingProps} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const abortController = useRef();

  const [updateShipment] = useMutation(updateShipmentMutation(), {
    refetchQueries: ["FetchShipment"],
  });

  const { data: { shipment } = {}, loading } = useQuery(
    fetchShipment({
      category: {},
      consignee: {
        contact: {},
      },
      consignor: {
        contact: {},
      },
      currency: {},
      delCountry: {},
      oriContact: {
        contact: {},
      },
      oriCountry: {},
      shipmentDescriptions: {},
      shipper: {
        contact: {},
      },
      warehouseMoves: true,
    }),
    {
      variables: { id: +id },
    },
  );

  const { data: { warehouseMoveV2: { edges = [] } = {} } = {} } = useQuery(
    fetchWarehouseMoveV2({
      site: true,
    }),
    {
      variables: {
        input: {
          _shipment: {
            id: {
              operator: "eq",
              value: shipment?.id,
            },
          },
        },
      },
    },
  );

  const shipmentHasConfirmedItems =
    edges && edges.filter(edge => edge.siteId !== null)?.length > 0;

  const formik = useFormik({
    initialValues: shipment,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      jobId: Yup.number().nullable().positive().integer(),
      time: Yup.number().nullable().positive().integer(),
    }),
    onSubmit: values => {
      const changedFields = getChangedFields(values, shipment);

      if (Object.keys(changedFields).length > 0) {
        const controller = new AbortController();
        abortController.current = controller;
        updateShipment({
          variables: {
            input: {
              id: shipment?.id,
              ...changedFields,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.delivered === "") {
      formik.setFieldValue("delivered", null);
    }
    if (formik?.values?.date === "") {
      formik.setFieldValue("date", null);
    }
  }, [formik?.values]);

  useEffect(() => {
    if (formik?.values && abortController.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    formik,
    shipment,
    shipmentId: +id,
    loading,
    shipmentHasConfirmedItems,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="flex flex-1 overflow-x-auto">
        <Body {...bodyProps} loading={loading} />
      </div>
    </div>
  );
};

export default Wizard;
