import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import {
  CREATE_OFFER as createOfferMutation,
  UPDATE_OFFER as updateOfferMutation,
} from "../../../../../../../graphql/mutation/Offer";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_OFFER_STATUSES } from "../../../../../../../graphql/query/OfferStatus";
import { FETCH_ENTITIES } from "../../../../../../../graphql/query/Entity";
import useUser from "../../../../../../../hooks/useUser";

const AddOfferModal = props => {
  const { artworkId, closeModal, values } = props;

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { offerStatuses = [] } = {} } = useQuery(FETCH_OFFER_STATUSES);
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);

  const [createOffer, { loading: loadingCreate }] = useMutation(
    createOfferMutation({ contact: {}, currency: {}, status: {} }),
    { refetchQueries: ["FetchOffersV2"] },
  );
  const [updateOffer, { loading: loadingUpdate }] = useMutation(
    updateOfferMutation({ contact: {}, currency: {}, status: {} }),
    { refetchQueries: ["FetchOffersV2"] },
  );

  const mutateOffer = () => {
    const input = {
      contactId: formik?.values?.contactId,
      statusId: formik?.values?.statusId,
      currencyId: formik?.values?.currencyId,
      priority: formik?.values?.priority,
      amount: formik?.values?.amount,
      tbiAmount: formik?.values?.tbiAmount,
      holdId: formik?.values?.holdId,
      user: formik?.values?.user,
      notes: formik?.values?.notes,
    };

    if (values?.id) {
      input.id = values?.id;
    } else {
      input.artworkId = artworkId;
    }

    const action = values?.id ? updateOffer : createOffer;
    action({
      variables: {
        input,
      },
    }).then(() => {
      closeModal();
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: values
      ? {
          contactId: values?.contactId,
          statusId: values?.statusId,
          currencyId: values?.currencyId,
          priority: values?.priority,
          amount: values?.amount || 0,
          tbiAmount: values?.tbiAmount || 0,
          holdId: values?.holdId,
          user: values?.user,
          notes: values?.notes,
          date: values?.date,
          dateClosed: values?.dateClosed,
          tbiDate: values?.tbiDate,
          holdDate: values?.holdDate,
        }
      : {},
    enableReinitialize: true,
    validationSchema: Yup.object({
      contactId: Yup.number()
        .required("Please select a contact.")
        .typeError("Please select a contact."),
      statusId: Yup.number()
        .required("Please select a status.")
        .typeError("Please select a status."),
      currencyId: Yup.number()
        .required("Please select a currency.")
        .typeError("Please select a currency."),
      holdId: Yup.number().positive().integer().nullable(),
      amount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive("must be a positive value")
        .nullable(),
      tbiAmount: Yup.number()
        .typeError("Please enter a valid amount.")
        .positive("must be a positive value")
        .nullable(),
    }),
    onSubmit: mutateOffer,
  });

  const inputs = {
    className: "grid grid-cols-4 gap-8",
    inputs: [
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Contact", name: "contactId", type: "contactSearch" },
          {
            label: "Status",
            name: "statusId",
            type: "multi-select",
            options: offerStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
              isDisabled:
                item?.description !== "Pending" && values === undefined,
            })),
          },
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            isMulti: false,
            options: currencies?.map(currency => ({
              label: `${currency.description} (${currency.code})`,
              value: currency.id,
            })),
          },
          {
            name: "priority",
            options: [{ label: "Top Deal", value: -1 }],
            singleCheckbox: true,
            type: "checkbox",
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Amount", name: "amount", type: "number-currency" },
          { label: "TBI Amount", name: "tbiAmount", type: "number-currency" },
          {
            label: "Hold",
            name: "holdId",
            type: "instanceSearch",
            filterInput: [
              { statusId: { operator: "in", value: [1] } },
              {
                _edition: {
                  artworkId: {
                    operator: "eq",
                    value: artworkId,
                  },
                },
              },
            ],
          },
          {
            label: "User",
            name: "user",
            type: "multi-select",
            options:
              entities?.edges?.map(item => {
                return { label: item.name, value: item.name };
              }) || [],
          },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          {
            label: "Droite de Suite / ARR",
            name: "_",
            type: "arr",
            disabled: true,
          },
          { label: "Notes", name: "notes", rows: 5, type: "textarea" },
        ],
      },
      {
        className: "grid grid-cols-1 gap-4",
        inputs: [
          { label: "Date", name: "date", type: "date", disabled: true },
          {
            label: "Date Closed",
            name: "dateClosed",
            type: "date",
            disabled: true,
          },
          { label: "TBI Date", name: "tbiDate", type: "date", disabled: true },
          {
            label: "Hold Date",
            name: "holdDate",
            type: "date",
            disabled: true,
          },
        ],
      },
    ],
  };
  const inputProps = {
    formik,
    ...inputs,
  };

  const mutationLabel = values?.id ? "Edit" : "Add";

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">Add Offer</div>
        <div className="flex flex-row">
          <Button
            action="default"
            className="mr-8"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik?.resetForm();
            }}
          />
          <Button
            label={`${mutationLabel}${
              loadingUpdate || loadingCreate ? "ing" : ""
            } Offer`}
            disabled={loadingUpdate || loadingCreate || !formik?.isValid}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="mt-8 pb-8">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

const AddOffer = props => {
  const { artworkId } = props || {};

  const { hasPermission } = useUser();

  const modalProps = {
    body: AddOfferModal,
    closeOnBackdrop: false,
    artworkId,
    hideCloseButton: false,
    scale: "lg",
    disabled: !hasPermission("UPDATE_OFFER"),
  };

  return (
    <Modal {...modalProps}>
      <Button
        label="Add Offer"
        action="black"
        disabled={!hasPermission("UPDATE_OFFER")}
      />
    </Modal>
  );
};

export { AddOffer, AddOfferModal };
