import { useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  useDebounce,
  convertToFilter,
  convertArrayToFilterInputObj,
  getChangedFields,
} from "../../../../util";
import Table from "../../../Table";
import { Button, IconButton, Modal, Pagination, SearchBar } from "../..";

import {
  FETCH_INSTANCE as fetchInstance,
  FETCH_INSTANCES_V2 as fetchInstancesV2,
} from "../../../../graphql/query/Instance";

const LIMIT = 5;

const TableCheck = props => {
  const { values, selectedValue, onChange } = props;
  return (
    <div className="relative flex w-[40px] items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValue?.id === values.id}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values)}
        />
      </div>
    </div>
  );
};

const InstanceSearchInput = props => {
  const {
    disabled,
    error,
    filterInput = [],
    label,
    loading,
    onChange: setValue,
    redirect = false,
    value,
    warnOverwrite,
    disabledIfFilled,
  } = props;

  const filterInputObj = convertArrayToFilterInputObj(filterInput);

  const initialValues = {
    limit: LIMIT,
    ...filterInputObj,
  };

  const [filters, setFilters] = useState(initialValues);

  const [displayOverwriteWarning, setDisplayOverwriteWarning] = useState(false);

  const [selectedInstance, setSelectedInstance] = useState(false);
  const [displayTaWarning, setDisplayTaWarning] = useState(false);
  // update filters if filterInput changes
  // useEffect(() => {
  //   setFilters(prevState => ({ ...prevState, ...filterInputObj }));
  // }, [filterInput]);

  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const input = convertToFilter({
    values: debouncedFilters,
    filterKeys: [],
    skipKeys: ["limit", "offset"],
  });

  const {
    data: { instancesV2: { edges = [], count } = {} } = {},
    loading: fetching,
  } = useQuery(
    fetchInstancesV2({
      isBond: {},
      edition: { artwork: { artists: {}, images: { imgT: {} } } },
    }),
    { skip, variables: { input } },
  );
  const { data: { instance = {} } = {}, client } = useQuery(fetchInstance(), {
    skip: typeof value !== "number",
    variables: { id: value },
  });

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
      client.writeQuery({
        query: fetchInstance(),
        data: {},
      });
    } else {
      setSelectedValue({ id: value });
    }
  }, [value]);

  const formikSearch = useFormik({
    initialValues,
  });

  const onChange = values => {
    setFilters(prevState => ({ ...prevState, ...values }));
  };

  const handleSave = (closeModal, overwrite) => {
    if (warnOverwrite && !overwrite && value !== selectedValue.id) {
      setDisplayOverwriteWarning(true);

      if (selectedInstance?.isBond) {
        setDisplayTaWarning(true);
      }

      return;
    }

    setValue?.(selectedValue?.id || null);
    closeModal();
  };

  const searchBarProps = {
    name: "name",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit: LIMIT,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const handleOnClick = value => {
    value?.id === selectedValue?.id
      ? setSelectedValue(null)
      : setSelectedValue(value);

    if (value?.id === selectedValue?.id) {
      setSelectedValue(null);
    } else {
      setSelectedInstance(value);
      setSelectedValue(value);
    }
  };

  const tableProps = {
    headers: [
      {
        label: "",
        width: "50px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValue={selectedValue}
            onChange={handleOnClick}
          />
        ),
      },
      {
        label: "Artwork Image",
        name: "edition.artwork.images[0].imgT",
        type: "image",
      },
      {
        label: "Reference",
        name: "name",
        type: "custom",
        component: props => {
          const value = props.label || "";
          return (
            <Link
              className="underline"
              to={`/instance/${props?.values?.id}/details`}
            >
              {value}
            </Link>
          );
        },
      },
      {
        label: "Title",
        name: "edition.artwork.title",
        type: "label",
        widthInPx: 400,
      },
      {
        label: "Artist Name",
        name: "edition.artwork.artists.[0].name",
        type: "label",
      },
    ],
    limit: LIMIT,
    loading: fetching,
    rows: edges,
    variant: "lg",
  };

  useEffect(() => {
    onChange(formikSearch?.values);
    const changedFields = getChangedFields(
      formikSearch?.values,
      formikSearch?.initialValues,
    );
    if (Object.keys(changedFields).length > 0) {
      setFilters(prevState => ({ ...prevState, offset: 0 }));
    }
  }, [formikSearch?.values]);

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    disabled: disabled || (disabledIfFilled && value),
    onClose: () => setDisplayOverwriteWarning(false),
    body: ({ closeModal }) => {
      return (
        <>
          <div className="relative flex w-full flex-col p-8">
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col text-2xl font-bold">
                <div>Select {label}</div>
              </div>
              <div className="flex flex-col">
                <div>
                  <Button
                    action="default"
                    className="mr-8"
                    label="Cancel"
                    onClick={() => {
                      closeModal();
                      setSelectedValue(instance);
                    }}
                  />
                  <Button
                    action="default"
                    className="mr-8"
                    disabled={!selectedValue}
                    label="Clear Selection"
                    onClick={() => {
                      setSelectedValue(null);
                    }}
                  />
                  <Button
                    label={loading ? "SELECTING" : "SELECT"}
                    disabled={loading || fetching || (!value && !selectedValue)}
                    onClick={() => {
                      handleSave(closeModal, false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-12">
              <div className="grid grid-cols-2 gap-10 pb-12">
                <div>
                  <SearchBar {...searchBarProps} />
                </div>
              </div>
              <Table {...tableProps} />
              <div>
                <Pagination {...paginationProps} />
              </div>
            </div>
          </div>
          {displayOverwriteWarning && (
            <div className="absolute left-0 top-0 h-full w-full rounded-2xl bg-black/25">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-6">
                <p className="mb-4">
                  Are you sure you want to change the instance this warehouse
                  item is attached to?
                </p>

                {displayTaWarning && (
                  <>
                    <p className="mb-4 font-bold">Customs Control</p>
                    <p className="mb-4">
                      This work is under customs control, you should add this
                      fine art item to the import shipment
                    </p>
                  </>
                )}

                <div>
                  <Button
                    action="default"
                    className="mr-8"
                    label="No"
                    onClick={() => {
                      setDisplayOverwriteWarning(false);
                      setDisplayTaWarning(false);
                    }}
                  />
                  <Button
                    label="Yes"
                    onClick={() => {
                      handleSave(closeModal, true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
    },
  };

  return (
    <Modal {...modalProps}>
      <div className={`flex flex-1 flex-col`}>
        <div className={`block text-xs font-medium text-black`}>{label}</div>
        <div
          className={`mt-1 flex h-[38px] w-full items-center rounded border border-black focus:border-black focus:ring-0 ${disabled || (disabledIfFilled && value) ? "bg-gray-100" : ""}`}
        >
          <div className={` flex-1 px-3 py-2 text-base `}>
            {redirect ? (
              <Link
                target="_blank"
                className="underline"
                to={`/instance/${instance?.id}/details`}
                onClick={e => e.stopPropagation()}
              >
                {instance?.name}
              </Link>
            ) : (
              instance?.name
            )}
          </div>
          <IconButton variant="clean" title="Search Instance">
            <MagnifyingGlassIcon
              className={`h-5 w-5 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </div>
        {error && <small className="text-sm text-red-600">{error}</small>}
      </div>
    </Modal>
  );
};

export default InstanceSearchInput;
