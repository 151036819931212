import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useEffect, useState } from "react";

import { FETCH_CONTACTS_LIST as fetchContactsList } from "../../../../graphql/query/Contact";
import { CREATE_CONTACT as createContactMutation } from "../../../../graphql/mutation/Contact";
import { FETCH_CONTACT_TYPES } from "../../../../graphql/query/ContactType";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { FETCH_FIRST_CONTACTS } from "../../../../graphql/query/FirstContact";
import { Button, getInput, Spinner } from "../../../../components/ui";

const NewContact = props => {
  const { closeModal, isCreateOpen = false } = props || {};
  const navigate = useNavigate();
  const [createContact, { loading }] = useMutation(createContactMutation(), {
    refetchQueries: [fetchContactsList()],
  });

  const [
    fetchContactTypesLazily,
    { loading: contactTypesLoading, data: { contactTypes = [] } = {} },
  ] = useLazyQuery(FETCH_CONTACT_TYPES);

  const [
    fetchEntitiesLazily,
    { loading: entitiesLoading, data: { entities = {} } = {} },
  ] = useLazyQuery(FETCH_ENTITIES);

  const [
    fetchFirstContactsLazily,
    { loading: firstContactsLoading, data: { firstContacts = [] } = {} },
  ] = useLazyQuery(FETCH_FIRST_CONTACTS);

  useEffect(() => {
    if (isCreateOpen) {
      fetchContactTypesLazily();
      fetchEntitiesLazily();
      fetchFirstContactsLazily();
    }
  }, [isCreateOpen]);

  const contactTypeOptions =
    contactTypes?.map(item => ({
      label: item?.description,
      value: item?.id,
    })) || [];
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      surname: Yup.string(),
      email: Yup.string().email("Please enter a valid email."),
      // company: Yup.string().required("Please enter a company"),
      typeId: Yup.number().required("Please select a type"),
      salutation: Yup.string(),
      // rating is required if the contact type is a collector
      level: Yup.number().when("typeId", {
        is: typeId => typeId === 4,
        then: schema => schema.required("Please enter a rating"),
      }),
      firstcontacttype: Yup.number().when("salesUserId", {
        is: salesUserId => (salesUserId ? true : false),
        then: schema => schema.required("Please enter a first contact type"),
      }),
      firstUserId: Yup.number().when("salesUserId", {
        is: salesUserId => (salesUserId ? true : false),
        then: schema => schema.required("Please enter a first user"),
      }),
    }),
    onSubmit: async values => {
      if (!values.forename) {
        values.forename = "";
      }
      createContact({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createContact: { success, contact } = {} },
        } = resp || {};
        if (success) {
          navigate(`/contacts/${contact?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    { label: "First Name", name: "forename", type: "text" },
    {
      label: "Sales Contact",
      name: "salesUserId",
      options:
        entities?.edges?.map(item => {
          return { label: item.name, value: item.id };
        }) || [],
      type: "multi-select",
    },
    { label: "Surname", name: "surname", type: "text" },
    {
      label: "Type",
      name: "typeId",
      options: contactTypeOptions,
      type: "multi-select",
    },
    { label: "Company", name: "company", type: "text" },
    // { label: 'Email', name: 'email', type: 'text'  },
    { label: "Salutation", name: "salutation", type: "text" },
    // if type is collector, show rating
    formik?.values?.salesUserId
      ? {
          label: "Rating",
          name: "level",
          options: [
            { label: "A+", value: 1 },
            { label: "A", value: 2 },
            { label: "B", value: 3 },
            { label: "C", value: 4 },
            { label: "G", value: 5 },
          ],
          placeholder: "Select Rating",
          type: "multi-select",
        }
      : null,
    formik?.values?.salesUserId
      ? {
          label: "First Met Type",
          name: "firstcontacttype",
          options: firstContacts?.map(item => ({
            label: item.description,
            value: item.id,
          })),
          type: "multi-select",
        }
      : null,
    formik?.values?.salesUserId
      ? {
          label: "First Met User",
          name: "firstUserId",
          options: entities?.edges?.map(item => ({
            label: item?.name,
            value: item?.id,
          })),
          type: "multi-select",
        }
      : null,
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  const [duplicateContactWarning, setDuplicateContactWarning] = useState(false);
  const [duplicateContacts, setDuplicateContacts] = useState([]);
  const [searching, setSearching] = useState(false);
  const [fetchDuplicateContacts] = useLazyQuery(fetchContactsList());

  const resetDuplicateState = () => {
    setDuplicateContacts([]);
    setDuplicateContactWarning(false);
  };

  const checkForDuplicates = async formik => {
    resetDuplicateState();
    setSearching(true);

    if (duplicateContactWarning) {
      formik.submitForm();
      setSearching(false);
      return;
    }
    try {
      const variables = {
        input: {
          forename: {
            value: formik?.values?.forename,
            operator: "like",
          },
          company: {
            value: formik?.values?.company,
            operator: "like",
          },
          limit: 20,
        },
      };
      const { data } = await fetchDuplicateContacts({ variables });
      if (data?.contactsV2?.count > 0) {
        setDuplicateContactWarning(true);
        setDuplicateContacts(data.contactsV2.edges);
      } else {
        formik.submitForm();
      }
    } catch (err) {
      console.log("Error checking for duplicates", err);
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    resetDuplicateState();
  }, [formik?.values?.forename, formik?.values?.company]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          Add a new contact
        </div>

        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              resetDuplicateState();
            }}
          />
          <Button
            label={"Create"}
            loading={loading || searching}
            disabled={loading || hasErrors}
            onClick={() => {
              checkForDuplicates(formik);
            }}
            className="flex items-center whitespace-nowrap"
          />
        </div>
      </div>

      {contactTypesLoading || entitiesLoading || firstContactsLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="w-50 grid grid-cols-2 gap-4">
          {duplicateContactWarning && (
            <div className="col-span-2 rounded-md border p-4 text-sm">
              <p>
                <span className="font-bold">
                  {duplicateContacts.length} Possible Duplicate
                  {duplicateContacts.length > 1 && `s`} Found
                </span>{" "}
              </p>
              {duplicateContacts?.length > 0 && (
                <ul
                  className={`mt-2 grid list-disc gap-1 pl-6 ${duplicateContacts.length > 5 && "md:grid-cols-2"}`}
                >
                  {duplicateContacts.map((contact, i) => (
                    <li key={i}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`contacts/${contact.id}/details?status=all`}
                      >
                        {contact?.name}{" "}
                        <span className="text-grey-550">
                          {contact?.company && `(${contact?.company})`}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {inputs
            ?.filter(item => !!item)
            .map((item, index) => {
              const inputProps = {
                ...item,
                formik,
              };
              return <div key={index}>{getInput(inputProps)}</div>;
            })}
        </div>
      )}
    </div>
  );
};

export default NewContact;
