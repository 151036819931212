import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { FETCH_COUNTRIES } from "../../../graphql/query/Country";
import { FETCH_SHIPMENT as fetchShipment } from "../../../graphql/query/Shipment";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";

import routes from "../pages/Wizard/routes";
import {
  CONFIRM_SHIPMENT_ITEMS as confirmShipmentItemsMutation,
  CREATE_SHIPMENT_JOB as createShipmentJobMutation,
  CREATE_SHIPMENT_ONWARD as createShipmentOnwardMutation,
  CREATE_SHIPMENT_RETURN as createShipmentReturnMutation,
  REMOVE_SHIPMENT_ITEMS as removeShipmentItemsMutation,
} from "../../../graphql/mutation/Shipment";

import ShipmentSearch from "./ShipmentSearch";
import { useEffect, useState } from "react";
import { GridInputs } from "../../../components/Functional";
import { useFormik } from "formik";
import { FETCH_CONTACT as fetchContactQuery } from "../../../graphql/query/Contact";
import AddShipment from "./AddShipment";
import exportModule from "./Actions/ExportModule";
import AdhocReportBody from "./Actions/AdhocReportBody";
import { shipmentFilterStore } from "./useShipmentFilters";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const search = shipmentFilterStore.get();
  const pathnames = location?.pathname.split("/");
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const id = pathnames?.length > 2 ? pathnames[2] : null;
  const shipmentObj = {
    category: {},
    consignee: {
      contact: {},
    },
    consignor: {
      contact: {},
    },
    currency: {},
    delCountry: {},
    oriContact: {
      contact: {},
    },
    oriCountry: {},
    shipmentDescriptions: {},
    shipper: {
      contact: {},
    },
    warehouseMoves: true,
  };
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const { data: { shipment } = {} } = useQuery(fetchShipment(shipmentObj), {
    skip: !id,
    variables: { id: +id },
  });

  const [
    createShipmentJob,
    {
      data: { createShipmentJob: { error: createShipmentJobError } = {} } = {},
      loading: createShipmentJobLoading,
      reset: resetCreateShipmentJob,
    },
  ] = useMutation(createShipmentJobMutation());

  const [
    confirmShipmentItems,
    {
      data: {
        confirmShipmentItems: { error: confirmShipmentItemsError } = {},
      } = {},
      loading: confirmShipmentItemsLoading,
      reset,
    },
  ] = useMutation(confirmShipmentItemsMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2"],
  });

  const [
    removeShipmentWarehouseItems,
    {
      data: {
        removeShipmentWarehouseItems: { error: removeShipmentItemsError } = {},
      } = {},
      loading: removeShipmentItemsLoading,
      reset: resetRemove,
    },
  ] = useMutation(removeShipmentItemsMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2"],
  });

  const [
    createShipmentReturn,
    {
      data: {
        createShipmentReturn: { error: createShipmentReturnError } = {},
      } = {},
      loading: createShipmentReturnLoading,
      reset: returnReset,
    },
  ] = useMutation(createShipmentReturnMutation());

  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => {
    if (item?.href == path) {
      return item;
    } else if (item?.href?.includes(path)) {
      return item;
    }
  });

  const steps = [
    { label: "All Shipments", onClick: () => navigate("/shipments") },
    ...(shipment
      ? [
          {
            label: `Shipment ${shipment?.id}`,
            onClick: () => navigate(`${shipment?.id}/details`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${shipment?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const confirmCreateJobProps = {
    title: createShipmentJobError ? "Error" : "Create Job",
    scale: "sm",
    description: createShipmentJobError
      ? createShipmentJobError
      : "Are you sure you wish to create job for confirmed shipment items?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleConfirm = () => {
        createShipmentJob({
          variables: {
            id: shipment?.id,
          },
          update: (cache, { data }) => {
            const { createShipmentJob: { job, success } = {} } = data || {};
            if (success) {
              closeModal();
              navigate(`/jobs/${job.id}/details`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={createShipmentJobError ? "Close" : "Cancel"}
            action={createShipmentJobError ? "primary" : "default"}
            onClick={() => {
              resetCreateShipmentJob();
              closeModal?.();
            }}
          />
          {!createShipmentJobError && (
            <Button
              label={createShipmentJobLoading ? "Creating" : "create"}
              disabled={createShipmentJobLoading}
              onClick={handleConfirm}
            />
          )}
        </div>
      );
    },
  };

  const confirmShipmentItemsProps = {
    title: confirmShipmentItemsError ? "Error" : "Confirm Shipment Items",
    scale: "sm",
    description: confirmShipmentItemsError
      ? confirmShipmentItemsError
      : "Are you sure you wish to confirm all unconfirmed shipment items? This cannot be undone.",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleConfirm = () => {
        confirmShipmentItems({
          variables: {
            input: { id: shipment?.id },
          },
          update: (cache, { data }) => {
            const { confirmShipmentItems: { success } = {} } = data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={confirmShipmentItemsError ? "Close" : "Cancel"}
            action={confirmShipmentItemsError ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!confirmShipmentItemsError && (
            <Button
              label={confirmShipmentItemsLoading ? "Confirming" : "Confirm"}
              disabled={confirmShipmentItemsLoading}
              onClick={handleConfirm}
            />
          )}
        </div>
      );
    },
  };

  const removeShipmentItemsProps = {
    title: removeShipmentItemsError ? "Error" : "Remove Shipment Items",
    scale: "sm",
    description: removeShipmentItemsError
      ? removeShipmentItemsError
      : "Are you sure you want to remove all items?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleRemove = () => {
        removeShipmentWarehouseItems({
          variables: {
            id: shipment?.id,
          },
          update: (cache, { data }) => {
            const { removeShipmentWarehouseItems: { success } = {} } =
              data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={removeShipmentItemsError ? "Close" : "Cancel"}
            action={removeShipmentItemsError ? "primary" : "default"}
            onClick={() => {
              resetRemove();
              closeModal?.();
            }}
          />
          {!removeShipmentItemsError && (
            <Button
              label={removeShipmentItemsLoading ? "Removing" : "Remove"}
              disabled={removeShipmentItemsLoading}
              onClick={handleRemove}
            />
          )}
        </div>
      );
    },
  };

  const createOnwardShipmentProps = {
    title: undefined,
    scale: "md",
    closeOnBackdrop: false,
    description: undefined,
    body: ({ closeModal }) => {
      // eslint-disable-next-line
      const [onwardShipmentStage, setOnwardShipmentStage] = useState(0);

      const [
        createShipmentOnward,
        {
          error: createShipmentOnwardError,
          data: createShipmentOnwardData,
          reset: resetCreateShipmentOnward,
        },
        // eslint-disable-next-line
      ] = useMutation(createShipmentOnwardMutation());

      // eslint-disable-next-line
      const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: values =>
          createShipmentOnward({
            variables: {
              input: {
                id: shipment?.id,
                consigneeId: values?.contactId,
              },
            },
          }),
      });

      // eslint-disable-next-line
      const [fetchContact] = useLazyQuery(fetchContactQuery());

      // eslint-disable-next-line
      useEffect(() => {
        if (formik?.values?.contactId) {
          fetchContact({
            variables: {
              id: formik?.values?.contactId,
            },
          }).then(({ data }) => {
            formik.setFieldValue("name", data?.contact?.name);
            formik.setFieldValue("company", data?.contact?.company);
            formik.setFieldValue("address", data?.contact?.address);
            formik.setFieldValue("address2", data?.contact?.address2);
            formik.setFieldValue("address3", data?.contact?.address3);
            formik.setFieldValue("town", data?.contact?.town);
            formik.setFieldValue("county", data?.contact?.county);
            formik.setFieldValue("country", data?.contact?.country?.name);
            formik.setFieldValue("postcode", data?.contact?.postcode);
          });
        }
      }, [formik?.values?.contactId]);

      const inputs = {
        className: "grid grid-cols-2 gap-12",
        inputs: [
          {
            className: "grid grid-cols-1 gap-4",
            inputs: [
              { label: "", name: "contactId", type: "contactSearch" },
              { label: "Name", name: "name", type: "text" },
              { label: "Company", name: "company", type: "text" },
              { label: "Address", name: "address", type: "text" },
              { label: "", name: "address2", type: "text" },
              { label: "", name: "address3", type: "text" },
            ],
          },
          {
            className: "grid grid-cols-1 gap-4",
            inputs: [
              { label: "Town", name: "town", type: "text" },
              { label: "County", name: "county", type: "text" },
              {
                label: "Country",
                name: "country",
                type: "multi-select",
                options: countries?.map(item => ({
                  label: item?.name,
                  value: item?.id,
                })),
              },
              { label: "Postcode", name: "postcode", type: "text" },
            ],
          },
        ],
      };

      const inputProps = {
        ...inputs,
        formik,
      };

      if (
        createShipmentOnwardData &&
        !createShipmentOnwardData?.createShipmentOnward?.error
      ) {
        return (
          <div className="flex w-full flex-col gap-4 p-8">
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col text-2xl font-medium">
                Create Onward Shipment
              </div>
              <div className="flex gap-4">
                <Button
                  label={"Done"}
                  onClick={() => {
                    setOnwardShipmentStage(0);
                    formik?.resetForm();
                    navigate(
                      `/shipments/${createShipmentOnwardData?.createShipmentOnward?.shipment?.id}/details`,
                    );
                  }}
                />
              </div>
            </div>
            <div className="p-4">
              Onward shipment was created successfully. You will be redirected
              to the new shipment record.
            </div>
          </div>
        );
      }

      if (
        createShipmentOnwardError ||
        createShipmentOnwardData?.createShipmentOnward?.error
      ) {
        return (
          <div className="flex w-full flex-col gap-4 p-8">
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col text-2xl font-medium">
                Create Onward Shipment
              </div>
              <div className="flex gap-4">
                <Button
                  label={"Close"}
                  onClick={() => {
                    resetCreateShipmentOnward();
                    closeModal?.();
                    setOnwardShipmentStage(0);
                    formik?.resetForm();
                  }}
                />
              </div>
            </div>
            <div className="p-4">
              {createShipmentOnwardError?.message ||
                createShipmentOnwardData?.createShipmentOnward?.error}
            </div>
          </div>
        );
      }

      if (onwardShipmentStage === 1) {
        return (
          <div className="flex w-full flex-col gap-4 p-8">
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col text-2xl font-medium">
                Create Onward Shipment
              </div>
              <div className="flex gap-4">
                <Button action="default" label="Cancel" onClick={closeModal} />
                <Button label={"Next"} onClick={formik?.submitForm} />
              </div>
            </div>
            <div>Select a delivery address for the onward shipment</div>
            <GridInputs {...inputProps} />
          </div>
        );
      }
      return (
        <div className="flex w-full flex-col gap-4 p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-medium">
              Create Onward Shipment
            </div>
            <div className="flex gap-4">
              <Button action="default" label="Cancel" onClick={closeModal} />
              <Button
                label={"Next"}
                onClick={() => setOnwardShipmentStage(1)}
              />
            </div>
          </div>
          <div className="p-4">
            This will create a shipment with all the warehouse items from the
            existing shipment attached. It does not follow the usual rules for
            item selection so you may end up with items that are difficult to
            remove if you do not really mean to shipment all the warehouse
            items. Are you sure you want to continue?
          </div>
        </div>
      );
    },
  };

  const createReturnShipmentProps = {
    title: createShipmentReturnError ? "Error" : "Create Shipment Return",
    scale: "sm",
    description: createShipmentReturnError
      ? createShipmentReturnError
      : "Are you sure you wish to create return for this shipment?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleReturn = () => {
        createShipmentReturn({
          variables: {
            id: shipment?.id,
          },
          update: (cache, { data }) => {
            const { createShipmentReturn: { newShipmentId, success } = {} } =
              data || {};
            if (success) {
              closeModal();
              navigate(`/shipments/${newShipmentId}/details`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={createShipmentReturnError ? "Close" : "Cancel"}
            action={createShipmentReturnError ? "primary" : "default"}
            onClick={() => {
              returnReset();
              closeModal?.();
            }}
          />
          {!createShipmentReturnError && (
            <Button
              label={createShipmentReturnLoading ? "Confirming" : "Confirm"}
              disabled={createShipmentReturnLoading}
              onClick={handleReturn}
            />
          )}
        </div>
      );
    },
  };

  const copyDescriptionProps = {
    title: "Copy Description?",
    scale: "sm",
    description: "Do you wish to copy the Shipment description?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleCopyDescription = () => {
        navigator.clipboard.writeText(shipment?.shipmentDescriptions);
        closeModal();
      };

      return (
        <div className="flex flex-col">
          <div className="py-2">
            <p className="text-sm">
              {!shipment?.shipmentDescriptions
                ? "No Description Available"
                : ""}
            </p>
          </div>
          <div className="mt-4 flex">
            <Button
              className="mr-3"
              label={"Cancel"}
              action={"default"}
              onClick={() => {
                closeModal?.();
              }}
            />
            <Button
              disabled={!shipment?.shipmentDescriptions}
              label={"Copy description"}
              onClick={handleCopyDescription}
            />
          </div>
        </div>
      );
    },
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const [isExportReportOpen, setIsExportReportOpen] = useState(false);
  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Shipment",
        modalProps: {
          body: AddShipment,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "lg",
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !shipment?.id && disabled,
        label: "Export Shipment Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: shipmentObj,
          type: "Shipment",
          ids: shipment?.id ? [shipment?.id] : ids,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
      ...(shipment?.id
        ? [
            {
              label: "Create Job",
              modalProps: confirmCreateJobProps,
            },
            {
              label: "Confirm Shipment Items",
              modalProps: confirmShipmentItemsProps,
            },
            {
              label: "Remove Shipment Items",
              modalProps: removeShipmentItemsProps,
            },
            {
              label: "Create Onward",
              modalProps: createOnwardShipmentProps,
            },
            {
              label: "Create Return",
              modalProps: createReturnShipmentProps,
            },
            {
              label: "Copy Description",
              modalProps: copyDescriptionProps,
            },
          ]
        : []),
    ],
  };

  const searchModalProps = {
    body: ShipmentSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
    isSearchOpen,
    onClose: () => setIsSearchOpen(false),
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button
            label="Shipment Search"
            action="black"
            onClick={() => setIsSearchOpen(true)}
          />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
