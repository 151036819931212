import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button, Spinner } from "../../../../components/ui";

import { FETCH_CONTACTS_LIST as fetchContactsListQuery } from "../../../../graphql/query/Contact";
import { FETCH_CONTACT_TYPES } from "../../../../graphql/query/ContactType";
import { FETCH_COUNTRIES } from "../../../../graphql/query/Country";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { FETCH_MAILING_LISTS } from "../../../../graphql/query/MailingList";
import { contactFilterStore, useContactFilters } from "../useContactFilters";

import Actions from "./components/Actions";
import Addresses from "./components/Addresses";
import Affiliations from "./components/Affiliations";
import Attributes from "./components/Attributes";
import ClientInfo from "./components/ClientInfo";
import Collection from "./components/Collection";
import ContactDetails from "./components/ContactDetails";
import Events from "./components/Events";
import Handover from "./components/Handover";
import MaterialSent from "./components/MaterialSent";
import Offers from "./components/Offers";
import Query from "./components/Query";
import Requests from "./components/Requests";
import Sales from "./components/Sales";
import Sort from "./components/Sort";
import Tabs from "./components/Tabs";
import Auctions from "./components/Auctions";
import { useEffect } from "react";

const ContactSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};

  const [
    fetchContactTypesLazily,
    { loading: contactTypesLoading, data: { contactTypes = [] } = {} },
  ] = useLazyQuery(FETCH_CONTACT_TYPES);

  const [
    fetchCountriesLazily,
    { loading: countriesLoading, data: { countries = [] } = {} },
  ] = useLazyQuery(FETCH_COUNTRIES);

  const [
    fetchEntitiesLazily,
    {
      loading: entitiesLoading,
      data: { entities: { edges: entities = [] } = {} } = {},
    },
  ] = useLazyQuery(FETCH_ENTITIES);

  const [
    fetchMailingListsLazily,
    {
      loading: mailingListsLoading,
      data: { mailingLists: { edges: mailingLists = [] } = {} } = {},
    },
  ] = useLazyQuery(FETCH_MAILING_LISTS);

  useEffect(() => {
    if (isSearchOpen) {
      fetchContactTypesLazily();
      fetchCountriesLazily();
      fetchEntitiesLazily();
      fetchMailingListsLazily();
    }
  }, [isSearchOpen]);

  const [fetchContactsV2, { loading, data: { contactsV2 } = {} }] =
    useLazyQuery(fetchContactsListQuery());
  const navigate = useNavigate();
  const { formik } = useContactFilters();
  const input = contactFilterStore.get();

  const contactTypeOptions =
    contactTypes?.map(item => ({
      label: item?.description,
      value: item?.id,
    })) || [];
  const continentOptions =
    countries?.map(item => ({ label: item?.continent, value: item?.id })) || [];
  const countryOptions =
    countries?.map(item => ({ label: item?.name, value: item?.id })) || [];
  const entityOptions =
    entities?.map(item => ({ label: item?.name, value: item?.id })) || [];
  const mailingListOptions =
    mailingLists?.map(item => ({
      label: item?.description,
      value: item?.id,
    })) || [];

  const inputs = {
    className: "grid grid-cols-3 gap-4 mt-5",
    inputs: [
      {
        className: "grid-cols-2 gap-4",
        inputs: [
          {
            hasOperator: true,
            label: "Name",
            name: "name",
            type: "text",
            className: "col-span-2",
          },
          { label: "First name", name: "forename", type: "text" },
          { label: "Surname", name: "surname", type: "text" },
          { label: "Company", name: "company", type: "text" },
          { label: "Job title", name: "title", type: "text" },
          {
            hasOperator: true,
            label: "Address",
            name: "address",
            type: "text",
          },
          { hasOperator: true, label: "Town/City", name: "town", type: "text" },
          { label: "County/State/Territory", name: "county", type: "text" },
          { label: "Postcode", name: "postcode", type: "text" },
          {
            hasOperator: true,
            isMulti: true,
            label: "Country",
            name: "countryId",
            options: countryOptions,
            type: "multi-select",
          },
          // continent search uses the country table which creates issues
          // { isMulti: true, label: "Continent", name: "countryId", options: continentOptions, type: "multi-select" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            className: "p-4 border rounded gap-4",
            inputs: [
              {
                hasOperator: true,
                isMulti: true,
                label: "Primary Sales Contact",
                name: "salesUserId",
                options: entityOptions,
                type: "multi-select",
              },
              {
                hasOperator: true,
                isMulti: true,
                label: "Secondary Sales Contact",
                name: "salesUser2Id",
                options: entityOptions,
                type: "multi-select",
              },
            ],
          },
          {
            className: "gap-4",
            inputs: [
              {
                hasOperator: true,
                isMulti: true,
                label: "Exhibitions Liason",
                name: "exhUserId",
                options: entityOptions,
                type: "multi-select",
              },
              {
                label: "Important Info",
                name: "prefered",
                type: "text",
              },
            ],
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { hasOperator: true, label: "ID", name: "id", type: "number" },
          {
            isMulti: true,
            label: "Mailing List",
            name: "_contactMailingList.mailingListId",
            options: mailingListOptions,
            type: "multi-select",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Type",
            name: "typeId",
            options: contactTypeOptions,
            type: "multi-select",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchContactsV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/contacts?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    contactTypeOptions,
    contactsV2,
    continentOptions,
    countryOptions,
    entityOptions,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Contact Details",
        value: "contact_details",
        component: <ContactDetails {...tabComponentProps} />,
      },
      {
        label: "Addresses",
        value: "addresses",
        component: <Addresses {...tabComponentProps} />,
      },
      {
        label: "Client Info",
        value: "client_info",
        component: <ClientInfo {...tabComponentProps} />,
      },
      {
        label: "Collection",
        value: "collection",
        component: <Collection {...tabComponentProps} />,
      },
      {
        label: "Material Sent",
        value: "material_sent",
        component: <MaterialSent {...tabComponentProps} />,
      },
      {
        label: "Sales",
        value: "sales",
        component: <Sales {...tabComponentProps} />,
      },
      {
        label: "Offers",
        value: "offers",
        component: <Offers {...tabComponentProps} />,
      },
      {
        label: "Requests",
        value: "requests",
        component: <Requests {...tabComponentProps} />,
      },
      {
        label: "Affiliations",
        value: "affiliations",
        component: <Affiliations {...tabComponentProps} />,
      },
      {
        label: "Events",
        value: "events",
        component: <Events {...tabComponentProps} />,
      },
      {
        label: "Handover",
        value: "handover",
        component: <Handover {...tabComponentProps} />,
      },
      {
        label: "Actions",
        value: "actions",
        component: <Actions {...tabComponentProps} />,
      },
      {
        label: "Attributes",
        value: "attributes",
        component: <Attributes {...tabComponentProps} />,
      },
      {
        label: "Auctions",
        value: "auctions",
        component: <Auctions {...tabComponentProps} />,
      },
      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "Query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Contact Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>

      {contactTypesLoading ||
      countriesLoading ||
      entitiesLoading ||
      mailingListsLoading ? (
        <div className="flex h-[70dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <GridInputs {...inputProps} />
          <Tabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};

export default ContactSearch;
